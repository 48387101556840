

// Mixpanel
import mixpanel_client from 'mixpanel-browser' // client
mixpanel_client.init("5a53383123e5b92e2aa56f4c44188b3c") // client



// CLIENT
export const mixpanel_client_track = (event_name, user_id) => {
  mixpanel_client.track(event_name, {   
    distinct_id: user_id, // if null, then it'll be an anonymous user
    is_dev: false
  });
}